import * as ProDuotoneSvgIcons from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { News } from 'src/tailwind/components/News'
import { Section } from 'src/tailwind/components/Section'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from 'src/views/components/Pagination'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function NewsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <SuspenseWrapper title={t('news:news')}>
      <PageContent />
    </SuspenseWrapper>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const userState = useUserState()
  const [searchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const page = searchParams.get('page')!
  const perPage = searchParams.get('perPage')!
  const headers = useAuthenticatedHeaders()
  const { pathname } = useLocation()

  const fetching = useApi({
    endpoint: Api.getNews,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          perPage: parseInt(perPage),
          page: parseInt(page),
        },
      }),
      [headers, page, perPage]
    ),
  })

  useUserStateMutateOnMount()

  const { data, meta } = fetching.data
  if (userState.data == null) return null

  const breadcrumbsItems = [{ page: `${t('news:news')}`, path: pathname }]

  return (
    <Section
      title={t('news:news')}
      icon={<FontAwesomeIcon icon={ProDuotoneSvgIcons.faBullhorn} />}
      breadcrubms={breadcrumbsItems}
    >
      {data != null && data.length > 0 ? (
        <>
          <PaginationTop pagination={meta.pagination} />

          {data.map((d) => (
            <div className="relative" key={d.id} data-testid={`news/${d.id}`}>
              <News
                to={d.id}
                key={d.id}
                classNames={{ li: 'my-2', span: !d.userViewStatus?.isViewed ? 'font-semibold' : '' }}
                date={format(parseISO(d.createdAt), 'd MMMM yyyy, HH:mm', { locale: dateLocale })}
                title={d.title}
              />
              {!d.userViewStatus?.isViewed && (
                <div
                  className="absolute right-3 top-1/2 z-10 mx-auto my-0 size-3 rounded-full bg-[#519ED9]"
                  data-testid="viewStatusIndicator"
                />
              )}
            </div>
          ))}
          <PaginationBottom pagination={meta.pagination} />
        </>
      ) : (
        <NoContent header={t('error:empty_page')} image={Warning} subHeader={t('error:news_not_found')} />
      )}
    </Section>
  )
}
