import { useSearchParams } from 'react-router-dom'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester, useAvailableSemesters } from 'src/hooks/semesters'
import { Button } from 'src/tailwind/components/Button'
import Select from 'src/views/components/Select'
import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Loader from '../../../components/Loader'

export default function MessagesForm(): JSX.Element {
  const t = useTranslatable()
  const [, setSearchParams] = useSearchParams()
  const activeSemester = useActiveSemester()
  const semesters = useAvailableSemesters()

  return (
    <div className="w-full">
      {semesters == null || activeSemester == null ? (
        <Loader className="m-auto flex" style={{ margin: 'auto' }} />
      ) : (
        <Select
          className="mb-4 w-full"
          defaultValue={{ id: activeSemester.id }}
          options={semesters.map((semester) => ({ id: semester.id }))}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ id }) => {
            const sem = semesters.find((semester) => semester.id === id)

            if (sem == null) return ''

            return sem.name + (sem.isActive ? t('common:current') : '')
          }}
          onChange={(opt) => {
            if (opt?.id == null) return

            setSearchParams((params) => {
              params.set('semId', opt.id)

              return params
            })
          }}
        />
      )}

      <Form
        initialValues={{ query: '' }}
        onSubmit={async ({ query }) => {
          if (query.length === 0 || query.length > 1) {
            setSearchParams((params) => {
              params.set('query', query)

              return params
            })
          }
        }}
        classNames={{ form: 'mb-2 w-full flex flex-wrap md:!flex-nowrap justify-between' }}
      >
        <div className="w-full">
          <TextInput type="text" name="query" placeholder={t('common:enter_your_keyword')} searchField />
        </div>
        <Button type="submit" variant="red" className="ml-0 w-[120px] md:ml-4">
          {t('common:search')}
        </Button>
      </Form>
    </div>
  )
}
