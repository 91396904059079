import classnames from 'classnames'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { LibraryIcon } from 'src/assets/icons/customIcons/LibraryIcon'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from '../../components/Pagination'

export default function LibraryPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('library:library_taken_books')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const dateLocale = useDateLocale()
  const [searchParams] = useSearchParams({
    page: '1',
    perPage: '10',
  })
  const headers = useAuthenticatedHeaders()
  const page = searchParams.get('page')!
  const perPage = searchParams.get('perPage')!

  const fetching = useApi({
    endpoint: Api.getLibraryBooks,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          perPage: parseInt(perPage),
          page: parseInt(page),
        },
      }),
      [headers, page, perPage]
    ),
  })

  const { data, meta } = fetching.data
  const { pathname } = useLocation()

  const breadcrumbsItems = [{ page: `${t('library:library_taken_books')}`, path: pathname }]

  return (
    <Section
      title={t('library:library_taken_books')}
      icon={
        <div className="w-[24px]">
          <LibraryIcon />
        </div>
      }
      breadcrubms={breadcrumbsItems}
    >
      {data != null && data.length > 0 ? (
        <>
          <PaginationTop pagination={meta.pagination} />
          <Table.Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('library:barcode')}</Table.Th>
                <Table.Th>{t('common:title')}</Table.Th>
                <Table.Th>{t('common:author')}</Table.Th>
                <Table.Th>{t('library:hall')}</Table.Th>
                <Table.Th>{t('library:withdrawal_date')}</Table.Th>
                <Table.Th>{t('library:return_date')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {data.map((item) => (
                <Table.Tr
                  key={item.id}
                  className={classnames({
                    '!hover:bg-transparent bg-seconderyRed/10': item.isOverdue,
                  })}
                >
                  <Table.Td>{item.barcode}</Table.Td>
                  <Table.Td>{item.title}</Table.Td>
                  <Table.Td>{item.author}</Table.Td>
                  <Table.Td>{item.place}</Table.Td>
                  <Table.Td>{format(parseISO(item.takenAt), 'd MMMM yyyy', { locale: dateLocale })}</Table.Td>
                  <Table.Td>{format(parseISO(item.returnedAt), 'd MMMM yyyy', { locale: dateLocale })}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.Table>
          <PaginationBottom pagination={meta.pagination} />
        </>
      ) : (
        <NoContent
          header={t('error:records_not_found')}
          image={Warning}
          subHeader={t('library:your_taken_books_list_is_empty')}
        />
      )}
    </Section>
  )
}
